import React, { useState } from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import config from './config';
import { useNavigate } from 'react-router-dom';

const OnboardingForm = ({ token, setHasOnboarded }) => {

const isStepValid = () => {
    switch (currentQuestion.step) {
      case 1:
        if (!formData.useCase) return false;
        if (selectedOption?.followUp && !formData.followUpResponse) return false;
        return true;
      case 2:
        return !!formData.monthlyVolume;
      case 3:
        return formData.selectedChallenges.length > 0;
      case 4:
        if (!formData.industry) return false;
        if (formData.industry === 'other' && !formData.followUpResponse) return false;
        return true;
      case 5:
        if (!formData.budgetTier) return false;
        if (formData.budgetTier === 'undecided' && !formData.followUpResponse) return false;
        return true;
      default:
        return false;
    }
  };

  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
  useCase: '',
  monthlyVolume: '',
  selectedChallenges: [],
  industry: '',
  budgetTier: '',
  followUpResponse: '',
  follow_up_responses: {}  // Add this
});

 const handleSubmit = async () => {
    if (!isStepValid()) return;

    // Line 42: Update handleSubmit onboardingData
const onboardingData = {
  use_case: formData.useCase,
  monthly_volume: formData.monthlyVolume,
  challenges: formData.selectedChallenges,
  industry: formData.industry,
  budget_tier: formData.budgetTier,
  follow_up_responses: formData.follow_up_responses
};

    try {
      const response = await fetch(`${config.apiBaseUrl}/api/auth/onboarding/complete`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(onboardingData)
      });

      if (response.ok) {
        setHasOnboarded(true);
        localStorage.setItem('hasOnboarded', 'true');
        navigate('/playground', { replace: true });
      }
    } catch (error) {
      console.error('Failed to complete onboarding. Please try again');
    }
  };


   const questions = [{
       step: 1,
       title: "What describes your video redaction needs?",
       type: "radio",
       options: [
       {
           label: "Need to comply with privacy regulations",
           value: "privacy_compliance",
           followUp: {
               title: "Which regulation is most relevant to your work?",
               type: "text",
               placeholder: "E.g. GDPR, HIPAA, CCPA"
           }
       },
       {
           label: "Manage video evidence/documentation with PII",
           value: "evidence_pii"
       },
       {
           label: "Evaluating automated redaction solutions",
           value: "evaluating"
       },
       {
           label: "Other",
           value: "other",
           followUp: {
               title: "Please specify your needs",
               type: "text",
               placeholder: "Tell us more about your use case"
           }
       }]
   },
   {
       step: 2,
       title: "How many videos do you need to redact each month?",
       subtitle: "This helps us recommend the right solution for your needs",
       type: "radio",
       options: [{
           label: "1-5 videos per month",
           value: "low",

       },
       {
           label: "6-50 videos per month",
           value: "medium",

       },
       {
           label: "50+ videos per month",
           value: "enterprise",

       },
       {
           label: "Not sure yet",
           value: "unknown",
       }]
   },
   {
       step: 3,
       title: "What's your biggest challenge with redaction?",
       subtitle: "Select all that apply",
       type: "select",
       options: [{
           label: "Time-consuming manual work",
           value: "manual_effort",
       },
       {
           label: "High costs of current solutions",
           value: "cost",
       },
       {
           label: "Poor accuracy and quality",
           value: "quality",
       },
       {
           label: "Hard to handle high volumes",
           value: "scale",
       },
       {
           label: "Other",
           value: "other",

       }]
   },
   {
       step: 4,
       title: "Which industry best describes your organization?",
       type: "radio",
       options: [{
           label: "Healthcare & Medical",
           value: "healthcare"
       },
       {
           label: "Legal & Law Enforcement",
           value: "legal"
       },
       {
           label: "Media & Entertainment",
           value: "media"
       },
       {
           label: "Education & Research",
           value: "education"
       },
       {
           label: "Financial Services",
           value: "finance"
       },
       {
           label: "Government & Public Sector",
           value: "government"
       },
       {
           label: "Other",
           value: "other",
           followUp: {
               title: "Please specify your industry",
               placeholder: "E.g. Manufacturing, Retail, Technology"
           }
       }]
   },
   {
       step: 5,
       title: "What's your monthly budget for video redaction?",
       subtitle: "This helps us recommend suitable solutions",
       type: "radio",
       options: [{
           label: "Under $100/month",
           value: "tier_1"
       },
       {
           label: "$100-$500/month",
           value: "tier_2"
       },
       {
           label: "Over $500/month",
           value: "tier_3"
       },
       {
           label: "Don't know yet",
           value: "undecided",
           followUp: {
               title: "Expected budget range?",
               placeholder: "Any rough estimate helps us serve you better"
           }
       }]
   }];

   const currentQuestion = questions[step - 1];
   const handleNext = () => step < questions.length && setStep(step + 1);
   const handlePrevious = () => step > 1 && setStep(step - 1);
   const handleFollowUpChange = (e) => {
  setFormData(prev => ({
    ...prev,
    followUpResponse: e.target.value,
    follow_up_responses: {
      ...prev.follow_up_responses,
      [currentQuestion.step === 1 ? 'privacy_compliance' : currentQuestion.step === 4 ? 'industry' : 'budget']: e.target.value
    }
  }));
};
   const selectedOption = currentQuestion.options?.find(opt => opt.value === formData.useCase);

   const handleSelect = (option) => {
    switch (currentQuestion.step) {
      case 1:
        setFormData({
          ...formData,
          useCase: option.value,
          followUpResponse: option.value === 'privacy_compliance' || option.value === 'other'
            ? formData.followUpResponse : ''
        });
        break;
      case 2:
        setFormData({ ...formData, monthlyVolume: option.value });
        break;
      case 3:
        setFormData(prev => ({
          ...prev,
          selectedChallenges: prev.selectedChallenges.includes(option.value)
            ? prev.selectedChallenges.filter(v => v !== option.value)
            : [...prev.selectedChallenges, option.value]
        }));
        break;
      case 4:
        setFormData({
          ...formData,
          industry: option.value,
          followUpResponse: option.value !== formData.industry ? '' : formData.followUpResponse
        });
        break;
      case 5:
        setFormData({
          ...formData,
          budgetTier: option.value,
          followUpResponse: option.value !== formData.budgetTier ? '' : formData.followUpResponse
        });
        break;
    }
  };

   return (
    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-white">
      <div className="max-w-2xl mx-auto px-4 py-16">
        <div className="mb-8">
          <div className="flex items-center justify-between mb-4">
            <div className="inline-block px-3 py-1 bg-[#9DC45F]/10 border border-[#9DC45F]/30 text-[#7A9F3E] rounded-full text-xs font-medium">
              Step {step} of {questions.length}
            </div>
            <div className="flex gap-2">
              {step > 1 && (
                <button onClick={handlePrevious}
                  className="p-2 rounded-lg hover:bg-white/80 transition-colors text-gray-600 hover:text-[#7A9F3E]">
                  <ChevronLeft className="w-5 h-5" />
                </button>
              )}
              {step < questions.length && (
                <button onClick={handleNext}
                  className="p-2 rounded-lg hover:bg-white/80 transition-colors text-gray-600 hover:text-[#7A9F3E]">
                  <ChevronRight className="w-5 h-5" />
                </button>
              )}
            </div>
          </div>
          <div className="h-1 bg-gray-100 rounded-full overflow-hidden">
            <div className="h-1 bg-gradient-to-r from-[#9DC45F] to-[#7A9F3E] rounded-full transition-all duration-300"
              style={{ width: `${(step / questions.length) * 100}%` }}
            />
          </div>
        </div>

        <div className="space-y-8">
          <h1 className="text-3xl font-medium text-gray-900">
            {currentQuestion.title}
          </h1>
          {currentQuestion.subtitle && (
            <p className="text-lg text-gray-600">{currentQuestion.subtitle}</p>
          )}

          <div className="space-y-4">
            {currentQuestion.options.map((option, index) => (
              <div key={index} className="space-y-3">
                <button onClick={() => handleSelect(option)}
                  className={`w-full p-4 text-left rounded-lg transition-all duration-300 ring-1
                    ${currentQuestion.type === 'select'
  ? formData.selectedChallenges.includes(option.value)
    ? 'ring-[#9DC45F] bg-[#9DC45F]/10'
    : 'ring-gray-200 hover:ring-[#9DC45F]/50 bg-white hover:bg-[#9DC45F]/5'
  : (currentQuestion.step === 1 ? formData.useCase === option.value
    : currentQuestion.step === 2 ? formData.monthlyVolume === option.value
    : currentQuestion.step === 4 ? formData.industry === option.value
    : currentQuestion.step === 5 ? formData.budgetTier === option.value
    : false)
    ? 'ring-[#9DC45F] bg-[#9DC45F]/10'
    : 'ring-gray-200 hover:ring-[#9DC45F]/50 bg-white hover:bg-[#9DC45F]/5'
}`}>
                  <span className="text-gray-900">{option.label}</span>
                </button>

                {option.followUp && formData.useCase === option.value && (
                  <div className="pl-4">
                    <label className="block text-sm font-medium text-gray-700 mb-2">
                      {option.followUp.title}
                    </label>
                    <input
                      type="text"
                      value={formData.followUpResponse}
                      onChange={handleFollowUpChange}
                      placeholder={option.followUp.placeholder}
                      className="w-full p-3 border border-gray-200 rounded-lg focus:ring-2 focus:ring-[#9DC45F]/20 focus:border-[#9DC45F] transition-all bg-white text-gray-900 placeholder-gray-500"
                    />
                  </div>
                )}
              </div>
            ))}
          </div>

          <div className="pt-6">
            {step === questions.length ? (
              <button
                onClick={handleSubmit}
                disabled={!isStepValid()}
                className="w-full py-4 px-6 bg-[#9DC45F] text-white rounded-lg hover:bg-[#8AB54E] transition-all shadow-lg hover:shadow-xl font-medium text-lg transform hover:-translate-y-0.5"
              >
                Get Started
              </button>
            ) : (
              <button
                  onClick={handleNext}
                  disabled={!isStepValid()}
                  className="w-full py-4 px-6 bg-[#9DC45F] text-white rounded-lg hover:bg-[#8AB54E] transition-all shadow-lg hover:shadow-xl font-medium text-lg transform hover:-translate-y-0.5 disabled:opacity-50 disabled:cursor-not-allowed"
                >
                Continue
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OnboardingForm;