import { Shield } from "lucide-react";

const SecurityBanner = () => {
  return (
    <div className="bg-[#9DC45F]/10 border border-[#9DC45F]/20 rounded-lg p-6 mb-6">
      <div className="flex items-center">
        <div className="flex-shrink-0">
          <div className="p-2 bg-[#9DC45F]/20 rounded-lg">
            <Shield className="h-5 w-5 text-[#7A9F3E]" />
          </div>
        </div>
        <div className="ml-4">

          <p className="mt-1 text-sm text-gray-600">
            Your videos are encrypted at rest and automatically deleted after 1 hour to ensure maximum data protection.
          </p>
        </div>
      </div>
    </div>
  );
};

export default SecurityBanner;