import React from 'react';
import { motion } from 'framer-motion';

const LogoLoading = () => {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-white z-50">
      <div className="flex flex-col items-center gap-6">
        {/* Logo Container */}
        <div className="relative w-32 h-32">
          {/* First dot animation */}
          <motion.div
            className="absolute w-3 h-3 rounded-full bg-[#9DC45F]"
            initial={{ x: 0, opacity: 0 }}
            animate={{
              x: [0, 20, 0],
              opacity: [0, 1, 0],
              scale: [0.8, 1, 0.8]
            }}
            transition={{
              duration: 2,
              repeat: Infinity,
              ease: "easeInOut"
            }}
          />

          {/* Second dot animation */}
          <motion.div
            className="absolute w-3 h-3 rounded-full bg-[#9DC45F]"
            initial={{ x: 20, opacity: 0 }}
            animate={{
              x: [20, 40, 20],
              opacity: [0, 1, 0],
              scale: [0.8, 1, 0.8]
            }}
            transition={{
              duration: 2,
              repeat: Infinity,
              ease: "easeInOut",
              delay: 0.4
            }}
          />

          {/* Main logo */}
          <motion.img
            src="/favicon.svg"
            alt="PixelProbe"
            className="w-full h-full"
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{
              opacity: [0.5, 1, 0.5],
              scale: [0.8, 1, 0.8]
            }}
            transition={{
              duration: 2,
              repeat: Infinity,
              ease: "easeInOut"
            }}
          />
        </div>

        {/* Loading text */}
        <div className="text-gray-600 text-lg font-medium">
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: [0.5, 1, 0.5] }}
            transition={{
              duration: 1.5,
              repeat: Infinity,
              ease: "easeInOut"
            }}
          >
            Loading...
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default LogoLoading;