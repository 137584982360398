import React, { useState, useEffect } from 'react';
import { GoogleLogin } from '@react-oauth/google';
import { AlignJustify, X, Shield, Lock, TextCursor, FileText, Clock, Database, Building, Video, CheckCircle, Search, MousePointer2, Code, Cpu } from 'lucide-react';
import VideoRedactionAnimation from './VideoRedactionAnimation';
import { Link } from 'react-router-dom';


const LandingPage = ({ onGoogleSuccess, user }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [showAuthPrompt, setShowAuthPrompt] = useState(false);


  const stats = [
    { value: '99.9%', label: 'Detection Accuracy' },
    { value: '100K+', label: 'Videos Protected' },
    { value: '5x', label: 'Faster Than Manual' }
  ];

  const FeatureCard = ({ icon: Icon, title, description }) => (
  <div className="group relative h-80 overflow-hidden rounded-2xl bg-white shadow-sm border border-gray-100 hover:shadow-lg transition-all duration-300">
    <div className="absolute inset-0 p-8 flex flex-col items-center justify-center text-center space-y-6">
      <div className="p-4 rounded-xl bg-[#9DC45F]/10 mb-4">
        <Icon className="w-8 h-8 text-[#7A9F3E]" />
      </div>
      <h3 className="text-2xl font-semibold text-gray-900 transition-all duration-500 ease-in-out">
        {title}
      </h3>
      <p className="text-gray-500 text-sm leading-relaxed transition-all duration-500 ease-in-out">
        {description}
      </p>
      <div className="absolute bottom-4 left-1/2 -translate-x-1/2">
        <div className="w-12 h-1 bg-[#9DC45F]/20 rounded-full group-hover:bg-[#9DC45F] transition-all duration-300" />
      </div>
    </div>
  </div>
);

  const playgroundFeatures = [
    {
      icon: Shield,
      title: "Automated PII Redaction",
      description: "Intelligently redact sensitive content(e.g. faces, license plates and more) across your media with real-time tracking and temporal consistency."
    },
    {
      icon: TextCursor,
      title: "Natural Language Search",
      description: "Just describe what you want to find and redact. Our AI understands context and finds relevant objects instantly."
    },
    {
      icon: MousePointer2,
      title: "Interactive Precision Controls",
      description: "Select any object in a frame and let our advanced tracking algorithm automatically follow and redact it throughout the video."
    },
    {
      icon: FileText,
      title: "Text-Based Audio Redaction",
      description: "From audio to text and back. Highlight words in the transcript, and we'll seamlessly remove them from your audio."
    },
  ];

  const automatedFeatures = playgroundFeatures.slice(0, 2);
  const interactiveFeatures = playgroundFeatures.slice(2, 4);


  useEffect(() => {
    // Clear Google OAuth state when auth prompt opens/closes
    if (showAuthPrompt) {
          // Clear any existing Google sign-in state
          if (window.google?.accounts?.id) {
        window.google.accounts.id.cancel();
        // Revoke all credentials
        window.google.accounts.id.revoke('', () => {});
    }
    }
  }, [showAuthPrompt]);

  const handleShowAuthPrompt = () => {
    // Clear any local auth state
    localStorage.removeItem('authToken');
    localStorage.removeItem('hasOnboarded');
    setShowAuthPrompt(true);
  };

  return (
    <div className="min-h-screen bg-white">
      <nav className="bg-white/90 backdrop-blur-sm border-b border-gray-100 fixed w-full z-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between h-16 items-center">
            <a href="/" className="h-12">
              <img src="/page-logo.svg" alt="PixelProbe" className="h-full" />
            </a>

            <div className="hidden md:flex items-center space-x-6">

             <button
  onClick={handleShowAuthPrompt}  // And here
  className="flex items-center px-4 py-2 border-2 border-[#9DC45F] rounded-lg hover:bg-[#9DC45F]/10 transition-all text-gray-700 font-medium"
>
  Sign In
</button>
            </div>
          </div>
        </div>
      </nav>

<section className="relative pt-40 pb-40 lg:pb-40 bg-gradient-to-b from-gray-50 to-white">
 <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
  <div className="flex flex-col lg:flex-row items-center justify-between gap-12">
    {/* Left content */}
    <div className="flex-1 max-w-xl pt-8">
      <div className="space-y-4">
        <div className="inline-block px-3 py-1 bg-[#9DC45F]/10 text-[#7A9F3E] rounded-full text-sm font-medium">
          AI-Powered
        </div>
        <h1 className="text-4xl md:text-5xl lg:text-6xl font-medium tracking-tight text-gray-900">
          Instant Privacy with Automated Redaction
        </h1>
        <p className="text-xl text-gray-600">
          Enable efficient and scalable redaction of sensitive data in videos, audios and AI workflows.
        </p>
      </div>
      <div className="flex gap-4 mt-8">
        <button
          onClick={() => setShowAuthPrompt(true)}
          className="px-5 py-2.5 border-2 border-[#9DC45F] text-gray-700 rounded-lg hover:bg-[#9DC45F]/10 transition-all font-medium"
        >
          Try Playground
        </button>
      </div>
    </div>
      {/* Right content */}

      <div className="lg:w-[50%]"> {/* Changed to percentage width */}
        <div className="w-full aspect-[16/10] bg-white rounded-xl overflow-hidden shadow-xl border border-gray-100"> {/* Changed aspect ratio */}

          <div className="w-full h-full">
            <VideoRedactionAnimation />
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

  <section className="py-32 bg-gradient-to-b from-gray-50 to-white">
  <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
    <div className="mb-32">
      <div className="text-center space-y-6 mb-24">
        <div className="inline-block px-3 py-1 bg-[#9DC45F]/10 text-[#7A9F3E] rounded-full text-sm font-medium">
          PixelProbe's Features
        </div>

        <div className="space-y-8">
          <h2 className="text-4xl md:text-5xl font-medium tracking-tight text-gray-900 max-w-2xl mx-auto">
            Effortless Redaction with Maximum Precision
          </h2>

          <div className="flex justify-center">
            <div className="w-20 h-1.5 bg-[#9DC45F] rounded-full" />
          </div>
        </div>
      </div>

      {/* Automated Detection & Search Section */}
      <div className="mb-24">
        <div className="grid md:grid-cols-2 gap-x-16 gap-y-16 max-w-5xl mx-auto">
          {automatedFeatures.map((feature, index) => (
            <FeatureCard
              key={index}
              {...feature}
              className="text-center"
            />
          ))}
        </div>
      </div>

      {/* Interactive Tools Section */}
      <div>
        <div className="grid md:grid-cols-2 gap-x-16 gap-y-16 max-w-5xl mx-auto">
          {interactiveFeatures.map((feature, index) => (
            <FeatureCard
              key={index}
              {...feature}
              className="text-center"
            />
          ))}
        </div>
      </div>
    </div>
  </div>
</section>

<footer className="bg-white py-12 border-t border-gray-100">
  <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
    <div className="flex flex-col items-center justify-center space-y-4">
      <a href="/" className="text-2xl font-medium text-gray-900">
        PixelProbe
      </a>
      <div className="flex gap-6 text-sm text-gray-500">
        <Link to="/legal#privacy" className="hover:text-gray-900">Privacy Policy</Link>
        <Link to="/legal#terms" className="hover:text-gray-900">Terms of Service</Link>
      </div>
      <p className="text-gray-500 text-sm">
        © {new Date().getFullYear()} PixelProbe. All rights reserved.
      </p>
    </div>
  </div>
</footer>

      {showAuthPrompt && (
  <div className="fixed inset-0 bg-black/20 backdrop-blur-sm z-50 flex items-center justify-center">
    <div className="relative bg-white p-8 rounded-xl shadow-2xl max-w-md w-full mx-4">
      <button
        onClick={() => setShowAuthPrompt(false)}
        className="absolute top-4 right-4 text-gray-400 hover:text-gray-600 transition-colors"
        aria-label="Close sign in prompt"
      >
        <X className="w-6 h-6" />
      </button>
      <div className="text-center space-y-6">
        <div className="inline-block px-3 py-1 bg-[#9DC45F]/10 text-[#7A9F3E] rounded-full text-sm font-medium">
          Beta Access
        </div>
        <h3 className="text-2xl font-semibold text-gray-900">
          Sign up for updates to get early access when we launch!
        </h3>
        <div className="space-y-3">
        </div>
        <div className="flex justify-center pt-2">
          <GoogleLogin
            onSuccess={onGoogleSuccess}
            onError={() => console.log('Login Failed')}
            useOneTap={false}
            auto_select={false}
            prompt="select_account"
            context="signin"
            cancel_on_tap_outside={true}
            ux_mode="popup"
          />
        </div>
      </div>
    </div>
  </div>
)}
      )}
    </div>
  );
};

export default LandingPage;