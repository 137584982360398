import React from 'react';
import { Link } from 'react-router-dom';
import { ArrowLeft, Shield, FileText } from 'lucide-react';

const LegalPage = () => {
  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-white pt-20">
      <div className="max-w-4xl mx-auto px-4 py-8">
        {/* Navigation Bar */}
        <nav className="flex items-center justify-between mb-8">
          <Link to="/" className="flex items-center gap-2 text-gray-600 hover:text-gray-900">
            <ArrowLeft className="w-4 h-4" />
            Back to Home
          </Link>
        </nav>

        {/* Quick Navigation */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-12">
          <button
            onClick={() => scrollToSection('privacy')}
            className="flex items-center gap-3 p-4 bg-white rounded-lg border border-gray-200 hover:border-[#9DC45F] transition-colors"
          >
            <Shield className="w-5 h-5 text-[#9DC45F]" />
            <div className="text-left">
              <h3 className="font-medium text-gray-900">Privacy Policy</h3>
              <p className="text-sm text-gray-500">How we handle your data</p>
            </div>
          </button>

          <button
            onClick={() => scrollToSection('terms')}
            className="flex items-center gap-3 p-4 bg-white rounded-lg border border-gray-200 hover:border-[#9DC45F] transition-colors"
          >
            <FileText className="w-5 h-5 text-[#9DC45F]" />
            <div className="text-left">
              <h3 className="font-medium text-gray-900">Terms of Service</h3>
              <p className="text-sm text-gray-500">Rules and guidelines</p>
            </div>
          </button>
        </div>

        {/* Privacy Policy Section */}
        <div id="privacy" className="mb-16 scroll-mt-24">
          <div className="bg-white rounded-xl p-8 shadow-sm border border-gray-100">
            <h2 className="text-3xl font-semibold text-gray-900 mb-8">Privacy Policy</h2>

            <div className="space-y-8">
              <section>
                <h3 className="text-xl font-medium text-gray-900 mb-4">Overview</h3>
                <p className="text-gray-600">
                  PixelProbe is committed to protecting your privacy. This privacy policy explains how we handle your data when you use our video redaction service.
                </p>
              </section>

              <section>
                <h3 className="text-xl font-medium text-gray-900 mb-4">Data We Collect</h3>
                <div className="space-y-4">
                  <div>
                    <h4 className="text-lg font-medium text-gray-800 mb-2">Personal Information</h4>
                    <ul className="list-disc pl-5 text-gray-600 space-y-2">
                      <li>Email address and name (during account creation)</li>
                      <li>This information is used only for account management and service-related communications</li>
                    </ul>
                  </div>
                  <div>
                    <h4 className="text-lg font-medium text-gray-800 mb-2">Video Content</h4>
                    <ul className="list-disc pl-5 text-gray-600 space-y-2">
                      <li>Videos that you upload for redaction</li>
                      <li>Basic usage metrics to improve our service</li>
                      <li>Processing timestamps and completion status</li>
                    </ul>
                  </div>
                </div>
              </section>

              <section>
                <h3 className="text-xl font-medium text-gray-900 mb-4">Data Security</h3>
                <ul className="list-disc pl-5 text-gray-600 space-y-2">
                  <li>Videos are encrypted while stored</li>
                  <li>Videos are automatically deleted after 1-2 hours</li>
                  <li>No backups or copies are retained</li>
                  <li>Processing occurs in temporary, isolated environments</li>
                </ul>
              </section>

              <section>
                <h3 className="text-xl font-medium text-gray-900 mb-4">Contact</h3>
                <p className="text-gray-600">
                  For any privacy concerns, please contact{' '}
                  <a href="mailto:shreya@pixelprobe.ai" className="text-[#9DC45F] hover:underline">
                    shreya@pixelprobe.ai
                  </a>
                </p>
              </section>
            </div>
          </div>
        </div>

        {/* Terms of Service Section */}
        <div id="terms" className="scroll-mt-24">
          <div className="bg-white rounded-xl p-8 shadow-sm border border-gray-100">
            <h2 className="text-3xl font-semibold text-gray-900 mb-8">Terms of Service</h2>

            <div className="space-y-8">
              <section>
                <h3 className="text-xl font-medium text-gray-900 mb-4">Service Description</h3>
                <p className="text-gray-600">
                  PixelProbe provides an automated video redaction service that helps users protect sensitive information in their videos.
                </p>
              </section>

              <section>
                <h3 className="text-xl font-medium text-gray-900 mb-4">Service Limitations</h3>
                <ul className="list-disc pl-5 text-gray-600 space-y-2">
                  <li>Maximum video length: 5 minutes</li>
                  <li>Maximum file size: 100MB</li>
                  <li>Maximum resolution: 1080p</li>
                  <li>Processing time varies based on video length and complexity</li>
                </ul>
              </section>

              <section>
                <h3 className="text-xl font-medium text-gray-900 mb-4">Data Handling</h3>
                <ul className="list-disc pl-5 text-gray-600 space-y-2">
                  <li>Videos are automatically deleted after 1-2 hours</li>
                  <li>Users must download processed videos within this timeframe</li>
                  <li>We are not responsible for lost videos after the deletion period</li>
                  <li>Users retain all rights to their content</li>
                </ul>
              </section>

              <section>
                <h3 className="text-xl font-medium text-gray-900 mb-4">Account Terms</h3>
                <ul className="list-disc pl-5 text-gray-600 space-y-2">
                  <li>One account per user</li>
                  <li>Accounts are for individual use only</li>
                  <li>Users must be 18 or older</li>
                  <li>Accounts may be terminated for violation of terms</li>
                </ul>
              </section>
            </div>
          </div>
        </div>

        {/* Last Updated */}
        <div className="text-center mt-8 text-sm text-gray-500">
          Last updated: December 2024
        </div>
      </div>
    </div>
  );
};

export default LegalPage;